interface Props {
  title: string;
}

export default function SubmitButton(props: Props) {
  return (
    <>
      <button
        className="border-2 border-main-blue mt-4 py-2 text-main-blue rounded-md w-1/2 self-center hover:bg-main-blue hover:text-black"
        type="submit">
        {props.title}
      </button>
    </>
  );
}
