import { verifyToken } from "@/api/invite";
import { Params, redirect } from "react-router-dom";

export default async function InviteVerifyLoader({params}: {params: Params}) {
  const token = params.token;

  if (token) {
    const response = await verifyToken(token);
    if (response.ok) {
      const data = await response.json();
      return data
    }
  }
  return redirect("/")
}