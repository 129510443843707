import { Form, useNavigation } from "react-router-dom";
import PasswordInput from "../../../../inputs/PasswordInput";
import LoadingButton from "../../../../buttons/LoadingButton";
import SubmitButton from "../../../../buttons/SubmitButton";
import useApiAction from "../../../../../api/utils/useApiAction";
import { UserDto } from "@/types/User";

export default function ChangePasswordView() {
  const navigation = useNavigation();
  useApiAction<UserDto>();

  const isLoading = navigation.state === "submitting" || navigation.state === "loading";
  return (
    <div className="flex w-screen h-screen justify-center flex-col">
      <h1 className="flex self-center mb-6 text-2xl text-center text-main-blue">Change password</h1>
      <Form method="post" className="flex self-center flex-col gap-3">
        <PasswordInput name="newPassword" title="New password" />
        <PasswordInput name="newPasswordAgain" title="New password again" />
        {isLoading ? <LoadingButton /> : <SubmitButton title="Change" />}
      </Form>
    </div>
  );
}
