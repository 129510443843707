import { ReactNode } from "react";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  admin?: boolean;
}

export default function SettingsButton({ children, admin, ...rest }: Props) {
  const defaultStyles = "text-main-blue text-2xl";
  const expandedStyles = `${defaultStyles} ${rest.className || ""}`.trim();
  
  return (
    <button className={expandedStyles} {...rest}>
      {children}
    </button>
  );
}
