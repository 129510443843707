import { useEffect } from "react";
import { navigationKeybinds } from "../keybinds/navigation-keybinds";
import { useNavigate } from "react-router-dom";

interface Keybinds {
  [key: string]: {
    [key: string]: string;
  };
}

export default function useKeypress(view: string) {
  const navigate = useNavigate();

  useEffect(() => {
    const onKeyup = (e: KeyboardEvent) => {
      const pressedKey = e.key;
      const press = (navigationKeybinds as unknown as Keybinds)[view];
      if (press && pressedKey && press[pressedKey]) {
        navigate(press[pressedKey]);
      }
    };
    window.addEventListener("keyup", onKeyup);
    return () => window.removeEventListener("keyup", onKeyup);
  }, []);
}
