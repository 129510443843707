import GamesView from "@/components/views/ProtectedView/games/GamesView";
import HappoMotelView from "@/components/views/ProtectedView/games/happomotel/HappoMotelView";
import { RouteObject } from "react-router-dom";

export const gameRoutes: RouteObject[] = [
  {
    path: "games",
    element: <GamesView />
  },
  {
    path: "games/happomotel",
    element: <HappoMotelView />
  }
];

