import { createRequest } from "./createRequest";

export async function login(username: string, password: string): Promise<Response> {
  return await createRequest("/auth/login", "post", false, { username, password });
}

export async function authorization(): Promise<Response> {
  return await createRequest("/auth/me", "get", true);
}

export async function logout(userId: number): Promise<Response> {
  return await createRequest("/auth/logout", "post", false, {userId});
}