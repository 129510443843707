import { login } from "../api/auth";
import { ApiResponse } from "@/types/ApiResponse";

export async function loginAction({ request }: { request: Request }): Promise<ApiResponse<any>> {
  const formData = await request.formData();
  const userName = formData.get("username") as string;
  const password = formData.get("password") as string;

  const response = await login(userName, password);
  const data = await response.json();
  localStorage.setItem("token", data?.data?.token);

  return data;
}