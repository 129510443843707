import { Form, useNavigation } from "react-router-dom";
import ViewContainer from "../../other/ViewContainer";
import MainLogo from "@/components/other/MainLogo";
import PasswordInput from "@/components/inputs/PasswordInput";
import TextInput from "@/components/inputs/TextInput";
import SubmitButton from "@/components/buttons/SubmitButton";
import LoadingButton from "@/components/buttons/LoadingButton";
import useApiAction from "@/api/utils/useApiAction";

export default function LoginView() {
  const navigation = useNavigation();
  const isLoading = navigation.state !== "idle";

  useApiAction();
  return (
    <ViewContainer>
      <div className="flex flex-col self-center p-12 border-main-blue border-2 rounded-md">  
        <div className="flex justify-center mb-6">
          <MainLogo tailwindSize="w-44" />
        </div>
        <Form method="post" action="/login" className="flex flex-col gap-3">
          <TextInput name="username" title="Username" />
          <PasswordInput name="password" title="Password" />
          {isLoading ? <LoadingButton /> : <SubmitButton title="Login" />}
        </Form>
      </div>
    </ViewContainer>
  );
}
