import { getInviteDetails } from "@/api/invite";
import { redirect } from "react-router-dom";

export default async function inviteLoader() {
  const response = await getInviteDetails();
  if (response.status !== 200) {
    return redirect("/home");
  }
  
  return await response.json();
}