import { ChatRoomDto, ChatRoomStatus } from "@/loaders/chatRoomsLoader";
import { useNavigate } from "react-router-dom";
import useApiLoader from "../../../../api/utils/useApiLoader";

export default function ChatRoomsView() {
  const navigate = useNavigate();
  const rooms = useApiLoader<ChatRoomDto[]>(false, true);
 
  return (
    <div className="container h-screen w-screen flex flex-col items-center">
      <h1 className="flex self-center m-20 text-4xl text-center text-main-blue">Rooms</h1>
      <table className="border border-main-blue w-full md:max-w-[50%]">
        <thead className="border border-main-blue text-left">
          <tr className="">
            <th className="p-2">Id</th>
            <th className="p-2">Name</th>
            <th className="p-2">Status</th>
            <th className="p-2 w-[14%]">Action</th>
          </tr>
        </thead>
        <tbody>
          {rooms?.map(room => {
            const statusColor = room.status === ChatRoomStatus.ONLINE ? "text-green-500" : "text-red-500" 
            return (
              <tr key={room.id} className="border border-main-blue">
                <td className="p-2">{room.id}</td>
                <td className="p-2">{room.name}</td>
                <td className={`p-2 ${statusColor}`}>{room.status}</td>
                <td className="p-2">
                  <button disabled={room.status === ChatRoomStatus.OFFLINE} 
                          className="disabled:opacity-30 border-2 border-main-blue text-main-blue rounded-md w-full self-center hover:bg-main-blue hover:text-black"
                          onClick={() => navigate(`/chat/${room.id}`)}>
                    Join
                  </button>
                </td>
              </tr>
            )
          })}

          {/* This invalid room is for tests and will be removed eventually */}
          <tr className="border border-main-blue">
            <td className="p-2">123</td>
            <td className="p-2">Invalid room</td>
            <td className="p-2 text-green-500">{ChatRoomStatus.ONLINE}</td>
            <td className="p-2">
                <button disabled={false} 
                        className="disabled:opacity-30 border-2 border-main-blue text-main-blue rounded-md w-full self-center hover:bg-main-blue hover:text-black"
                        onClick={() => navigate(`/chat/123`)}>
                  Join
                </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}