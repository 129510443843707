import { Role } from "@/types/Role";
import { UserDto } from "@/types/User";
import { ReactNode } from "react";
import { useRouteLoaderData } from "react-router-dom";

interface Props {
  children: ReactNode
}

export default function AdminOnly({children}: Props) {
  const userDetails = useRouteLoaderData("protected") as UserDto
  if (userDetails.role === Role.ADMIN) {
    return <>{children}</> 
  }
  return null;
}