import { useNavigate } from "react-router-dom";

interface Props {
  title: string;
  location: string;
  description: string;
}

export default function UtilCard(props: Props) {
  const navigate = useNavigate();

  const handleClick = () => navigate(props.location);
  return (
    <div
      onClick={handleClick}
      className="rounded-lg h-36 border-2 border-main-blue p-4 flex flex-col items-center cursor-pointer"
    >
      <div className="bg-main-blue rounded-full p-3"></div>
      <h3 className="text-xl font-semibold mt-4 mb-2">{props.title}</h3>
      <p className="text-center text-gray-500">{props.description}</p>
    </div>
  );
}
