import { createRequest } from "./createRequest";

export async function getAllTodos(): Promise<Response> {
  return await createRequest("/todo/all", "get", true);
}

export async function createTodo(text: string): Promise<Response> {
  return await createRequest("/todo/new", "post", true, { text });
}

export async function deleteTodo(id: string): Promise<Response> {
  return await createRequest(`/todo/${id}`, "delete", true);
}

export async function updateDone(id: string): Promise<Response> {
  return await createRequest(`/todo/${id}`, "put", true);
}