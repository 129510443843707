import { redirect } from "react-router-dom";
import { createShortUrl } from "../api/urlShortener";

export async function generateShortUrl({ request }: { request: Request }) {
  const formData = await request.formData();
  const longUrl = formData.get("longUrl") as string;

  const response = await createShortUrl(longUrl);
  if (response.ok) {
    return response
  }
  return redirect("/utils/url");
}