import { useRouteError } from "react-router-dom";
import ViewContainer from "../../other/ViewContainer";

export default function ErrorView() {
  const error = useRouteError() as { status: number; statusText: string };

  return (
    <ViewContainer>
      <div className="flex self-center flex-col items-center">
        <div className="text-main-blue text-6xl font-bold">{error?.status}</div>
        <div className="flex self-center text-main-blue text-2xl">
          {error.statusText}
        </div>
      </div>
    </ViewContainer>
  );
}
