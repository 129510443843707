import { useNavigate } from "react-router-dom";
import MainLogo from "../../other/MainLogo";
import ViewContainer from "../../other/ViewContainer";
import useKeypress from "../../../hooks/useKeypress";

export default function LandingView() {
  useKeypress("landingView");
  const navitate = useNavigate();

  return (
    <ViewContainer>
      <MainLogo onClick={() => navitate("/login")} />
    </ViewContainer>
  );
}
