import { useState } from "react";
import { Form, useSubmit } from "react-router-dom";
import { Todo } from "./Todo";
import TodoCard from "./TodoCard";
import useApiLoader from "../../../../../api/utils/useApiLoader";
import ViewContainer from "@/components/other/ViewContainer";

export default function TodoView() {
  const submit = useSubmit();
  const [text, setText] = useState("");
  
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("text", text);
    submit(formData, {
      method: "post",
      action: "/utils/todo",
    });
    setText("");
  };
  
  const todos = useApiLoader<Todo[]>(false, true);

  return (
    <ViewContainer>
      <div className="flex self-center flex-col items-center w-full h-full justify-center max-w-xl m-2">
        <div className="text-3xl text-main-blue font-bold">Todos</div>
        <Form method="POST" onSubmit={handleSubmit} className="mt-6 border-2 w-full border-main-blue bg-main-blue sm:h-10 flex flex-wrap">
          <div className="flex w-full bg-orange-500 flex-col sm:flex-row">
          <input
            onChange={(e) => setText(e.target.value)}
            name="text"
            className="bg-black w-full text-main-blue px-2 focus:outline-none"
            type="text"
            placeholder="New todo..."
            value={text}
          />
          <button disabled={text.length === 0}
                  className={`text-black px-6 ${text.length === 0 ? 'hidden' : 'bg-main-blue' }`}
                  type="submit">
            Submit
          </button>
          </div>
        </Form>
        <div className="w-full flex flex-col gap-2">
          {todos && todos.length > 0 &&
            todos.map((todo) => <TodoCard key={todo.id} todo={todo} />)}
        </div>
      </div>
    </ViewContainer>
  );
}
