import { useNavigate } from "react-router-dom";
import SettingsButton from "../../../buttons/SettingsButton";
import AdminOnly from "@/components/other/AdminOnly";

export default function SettingsView() {
  const navigate = useNavigate();
 
  return (
    <div className="flex h-screen w-screen justify-center flex-col h-full gap-3">
      <h1 className="flex self-center m-10 text-4xl text-center text-main-blue">Settings</h1>
      <SettingsButton admin onClick={() => navigate("/settings/invite")}>Invite</SettingsButton>
      <SettingsButton onClick={() => navigate("/settings/change-password")}>Change password</SettingsButton>
      <AdminOnly>
        <SettingsButton admin onClick={() => navigate("/settings/change-roles")}>Change roles</SettingsButton>
      </AdminOnly>
    </div>
  );
}