import { ApiResponse } from "@/types/ApiResponse";
import { createTodo, deleteTodo, updateDone } from "../api/todo";

// Handle todo calls
export async function todoAction({ request }: { request: Request }): Promise<ApiResponse<any>> {
  const formData = await request.formData();

  let response;
  
  // New todo
  if (request.method === "POST") {
    const text = formData.get("text") as string;
    response = await createTodo(text)
  }
  
  // Delete todo
  else if (request.method === "DELETE") {
    const id = formData.get("id") as string;
    response = await deleteTodo(id);
  }

  // Update todo
  else if (request.method === "PUT") {
    const id = formData.get("id") as string;
    response = await updateDone(id);
  }

  const data = await response?.json();
  return data;
}