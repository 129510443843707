import { createUser } from "@/api/user";
import { ApiResponse } from "@/types/ApiResponse";
import { UserDto } from "@/types/User";

export async function createUserAction({ request }: { request: Request }): Promise<ApiResponse<UserDto>> {

  // Form data
  const formData = await request.formData();
  const token = formData.get("token") as string;
  const username = formData.get("username") as string;
  const password = formData.get("password") as string;
  const passwordAgain = formData.get("passwordAgain") as string;
  
  const response = await createUser(token, username, password, passwordAgain);
  const data = await response.json()
  return data;
}