interface Props {
  children: JSX.Element;
}

export default function ViewContainer(props: Props) {
  return (
    <div className={"h-screen w-screen flex justify-center bg-black"}>
      {props.children}
    </div>
  );
}
