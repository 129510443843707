import SubmitButton from "@/components/buttons/SubmitButton";
import { InviteDetailsDto, InviteDto } from "@/types/Invite";
import { UserDto } from "@/types/User";
import { Form, useLoaderData, useRouteLoaderData } from "react-router-dom";
import useApiAction from "../../../../../api/utils/useApiAction";

export default function InviteView() {
  const currentUser = useRouteLoaderData("protected") as UserDto;
  const inviteDetails = useLoaderData() as InviteDetailsDto
  const invite = useApiAction<InviteDto>(true, true)
  const invitesLeft = currentUser.isAdmin ? "∞" : inviteDetails?.invitesLeft

  return (
    <div className="h-screen w-screen flex flex-col justify-center items-center">
      <div className="flex justify-center flex-col items-center">
          <h1 className="text-main-blue text-[80px]">{invitesLeft}</h1>
          <p className="text-main-blue">Invites left</p>
      </div>
      <div className="w-full max-w-[500px]">
        <Form method="post" action="/settings/invite" className="flex justify-center">
          <SubmitButton title="Generate invite" />
        </Form>
      </div>

      <div className="flex justify-center mt-10 text-center">
        <h1 className="text-xl">{invite ? invite?.inviteUrl : ""}</h1>
      </div>
    </div>
  )
}