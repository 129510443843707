import { ApiResponse } from "@/types/ApiResponse";
import { updateRole } from "../api/user";
import { Role } from "../types/Role";
import { UserDto } from "@/types/User";

export async function changeRoleAction({ request }: { request: Request }): Promise<ApiResponse<UserDto>> {
  const formData = await request.formData();
  const userId = parseInt(formData.get("userId") as string);
  const role = formData.get("role") as Role;

  const response = await updateRole(userId, role);
  const data = await response.json()

  return data;
}
