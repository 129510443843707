import { findAllRooms } from "@/api/chat";
import { ApiResponse } from "@/types/ApiResponse";

// TODO: Move
export interface ChatRoomDto {
  id: number
  name: string
  status: ChatRoomStatus
}

export enum ChatRoomStatus {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE"
}

export default async function chatRoomsLoader(): Promise<ApiResponse<ChatRoomDto[]>> {
  const response = await findAllRooms();
  const data = await response.json();
  return data;
} 