import { useRouteLoaderData, useSubmit } from "react-router-dom";
import { UserDto } from "../../../../../types/User";
import useApiAction from "../../../../../api/utils/useApiAction";
import useApiLoader from "@/api/utils/useApiLoader";

// TODO: Get these from the server
// for this we need role scores
const ROLES = ["ADMIN", "USER"];

export default function ChangeRolesView() {
  const submit = useSubmit();
  const users = useApiLoader<UserDto[]>(false, true); 
  const currentUser = useRouteLoaderData("protected") as UserDto;
  
  // Submit the form to the server with user id and new role
  const handleRoleChange = (event: React.ChangeEvent<HTMLSelectElement>, userId: number) => {
    const formData = new FormData();
    formData.append("userId", userId.toString());
    formData.append("role", event.target.value);
    submit(formData, {
      method: "post",
      action: "/settings/change-roles",
    });
  }
  
  useApiAction<UserDto>();
  
  return (
    <div className="flex w-screen h-screen justify-center flex-col">
      <h1 className="flex self-center m-20 text-2xl text-center text-main-blue">Change roles</h1>
      <ul className="flex">
        {users?.map((user) => {
          if (currentUser.userId !== user.userId) {
            return (
              <li key={user.userId} className="flex justify-center">
                <div className="flex flex-col p-4 m-4 bg-white rounded-lg shadow-lg">
                  <p className="text-lg text-main-blue text-center">{user.username}</p>
                  <select className="p-2 m-2 bg-main-blue text-white rounded-lg" 
                          onChange={(e): void => handleRoleChange(e, user.userId)} 
                          defaultValue={user.role}>
                    {ROLES.map((role) => (
                      <option key={role} value={role}>{role}</option>
                    ))}
                  </select>
                </div>
              </li>
            )}}
          )}
      </ul>
    </div>
  );
}
