import { useLoaderData, useRouteLoaderData } from "react-router-dom";
import UtilCard from "../../../other/UtilCard";
import { MyApp } from "../../../../routes/utilsRoutes";
import { UserDto } from "@/types/User";

export default function UtilsView() {
  const appList = useLoaderData() as MyApp[];
  const currentUser = useRouteLoaderData("protected") as UserDto

  // Filter adminOnly apps out if user has no admin rights
  const apps = currentUser?.isAdmin
    ? appList
    : appList.filter(app => !app.adminOnly)

  return (
    <div>
      <div className="flex justify-center align-center w-screen h-screen">
        <div className="flex md:flex-row flex-wrap flex-col justify-center gap-4 items-center">
          {apps.map((app, index) =>  (
            <UtilCard key={index}
                      title={app.title}
                      location={app.location}
                      description={app.description} />
          ))}
        </div>
      </div>
    </div>
  );
}
