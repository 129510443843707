import { Toaster } from "@/shadcd/components/ui/toaster";
import { Outlet } from "react-router-dom";

export default function RootView() {
  return (
    <div className="text-white">
      <Outlet />
      <Toaster />
    </div>
  );
}
