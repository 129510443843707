import { SOCKET_BASE_URL } from "@/api/config";
import useApiLoader from "@/api/utils/useApiLoader";
import Loader from "@/components/other/Loader";
import { ChatRoomDto } from "@/loaders/chatRoomsLoader";
import { useToast } from "@/shadcd/components/ui/use-toast";
import { UserDto } from "@/types/User";
import { useEffect, useState } from "react";
import { Navigate, useNavigate, useRouteLoaderData } from "react-router-dom";

interface ChatMessage {
  type: string,
  userId: number,
  username: string,
  message: string,
}

interface SocketData {
  message: ChatMessage
  userNames: string[]
}

export default function ChatView() {
  const currentUser = useRouteLoaderData("protected") as UserDto
  const navigate = useNavigate();
  const {toast} = useToast();
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [message, setMessage] = useState("");
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [users, setUsers] = useState<string[]>([])
  const room = useApiLoader<ChatRoomDto>(false, true);

  const buildMessage = (type: string, message: string) => {
    return JSON.stringify({type: type, userId: currentUser.userId, username: currentUser.username, message: message})
  }

  // Open socket
  useEffect(() => {
    if (room?.id && currentUser?.userId) {
      const wbUrl = `${SOCKET_BASE_URL}/chat/${room.id}/${currentUser.userId}`;
      const ws = new WebSocket(wbUrl)
      setSocket(ws);
    }
  }, [room?.id]);

  // Listen socket events
  useEffect(() => {
    if (socket) {
      socket.onopen = () => {
        socket.send(buildMessage("CONNECT", "joined the room"))
        toast({
          title: "Joined to room "+ room?.name,
        })
        console.log('WebSocket connected...');
      };

      // Handle messages from socket server
      socket.onmessage = (event) => {
        const data = JSON.parse(event.data) as SocketData
        setUsers(data.userNames || [])
        setMessages((prevMessages) => [...prevMessages, data.message]);
      };

      socket.onclose = () => {
        navigate("/chat/rooms")
        console.log('WebSocket disconnected...');
      };

      socket.onerror = () => {
        console.log('WebSocket error...');
        toast({
          title: room?.name + " is offline",
          variant: "destructive"
        })
      };

      return () => {
        if (socket.readyState === WebSocket.OPEN) {
          socket.send(buildMessage("DISCONNECT", "left the room"))
          socket.close();
        }
      };
    }
  }, [socket])

  const sendMessage = () => {
    if (socket) {
      socket.send(buildMessage("MESSAGE", message));
      setMessage("")
    }
  };

  if (!room) {
    return <Navigate to="/chat/rooms" />
  }

  if (socket?.readyState === socket?.CONNECTING) {
    return (
      <div className="container h-screen w-screen flex justify-center flex-col items-center">
        <Loader size={11} />
      </div>
    )
  }

  if (room && socket?.readyState === socket?.OPEN) {
    return (
      <div className="container h-screen w-screen flex justify-center flex-col items-center">
          {/* Header text */}
          <h1 className="flex self-center m-10 text-3xl text-center text-main-blue">
            {socket?.readyState === socket?.OPEN ? `${room.name}` : "Invalid room"}
          </h1>
          <div className="border border-main-blue w-full flex flex-col md:flex-row">
            {/* Left */}
            <div className="w-full">
              {/* Scrolling messages */}
              <div className="p-2 h-96 flex flex-col overflow-auto w-full">
                {messages.map((message, index) => {
                  return (
                    <div key={index}>
                      <div>{message?.username?.toUpperCase()}: {message.message}</div>
                    </div>
                  )
                })}
              </div>
              {/* Bottom input + button */}
              <div className="flex bg-black justify-between">
                <input autoFocus onKeyUp={e => e.key === "Enter" ? sendMessage() : null} value={message} onChange={e => setMessage(e.target.value) } className="w-full bg-black border border-main-blue outline-none px-2 py-1" type="text" name="message" placeholder="Type here"/>
                <button onClick={sendMessage} className="bg-black border border-main-blue text-main-blue w-20">Send</button>
              </div>
            </div>

            {/* Right */}
            <div className="border-l p-2 border-main-blue md:w-[160px]">
              {users.map(user => <div key={user} className="text-main-blue font-bold cursor-pointer truncate">{user}</div>)} 
            </div>
          </div>
        </div>
    )
  }
}