import { Form, useSubmit } from "react-router-dom";
import { Todo } from "./Todo";
import useApiAction from "../../../../../api/utils/useApiAction";

interface Props {
  todo: Todo;
}

export default function TodoCard({ todo }: Props) {
  const submit = useSubmit();

  // Send updated form data to the updateTodoAction
  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("id", todo.id.toString());
    submit(formData, {
      method: "put",
      action: "/utils/todo",
    });
  }

  useApiAction<any>();

  return (
    <div className="flex justify-between items-center p-2 cursor-pointer w-full">
      <div onClick={handleSubmit} className="grow">
        <div className="text-sm">{todo.createdAt.toString()}</div>
        <div className={`text-main-blue italic ${todo?.done ? "line-through" : ""}`}>
          {todo.text}
        </div>
      </div>
      <Form method="delete" action="/utils/todo">
        <input type="hidden" name="id" value={todo.id} />
        <button className="cursor-pointer w-1/6 text-center text-main-blue">
          Delete
        </button>
      </Form>
    </div>
  );
}
