import { RouteObject } from "react-router-dom";
import UtilsView from "../components/views/ProtectedView/utils/UtilsView";
import TodoView from "../components/views/ProtectedView/utils/todo/TodoView";
import todosLoader from "../loaders/todosLoader";
import { todoAction } from "@/actions/todoAction";
import UrlShortenerView from "../components/views/ProtectedView/utils/url-shortener/UrlShortenerView";
import { generateShortUrl } from "../actions/generateShortUrl";

export interface MyApp {
  title: string;
  location: string;
  description: string;
  adminOnly: boolean;
}

const appList: MyApp[] = [
  {
    title: "Todo",
    location: "/utils/todo",
    description: "A todo app",
    adminOnly: false
  },
  {
    title: "Url shortener",
    location: "/utils/url",
    description: "A url shortener",
    adminOnly: true,
  }
];

export const utilsRoutes: RouteObject[] = [
  {
    path: "utils",
    element: <UtilsView />,
    loader: () => appList,
  },

  // Todo
  {
    path: "utils/todo",
    element: <TodoView />,
    loader: todosLoader,
    action: todoAction
  },
  {
    path: "utils/url",
    element: <UrlShortenerView />,
    action: generateShortUrl,
  },
];
