import { RouteObject } from "react-router-dom";
import ErrorView from "../components/views/ErrorView/ErrorView";
import LoginView from "../components/views/LoginView/LoginView";
import { loginAction } from "../actions/loginAction";
import LandingView from "../components/views/LandingView/LandingView";
import urlShortnerLoader from "../loaders/urlShortnerLoader";
import inviteVerifyLoader from "@/loaders/inviteVerifyLoader";
import CreateUserView from "@/components/views/ProtectedView/settings/create-user/CreateUserView";
import { createUserAction } from "@/actions/createUserAction";
import { logoutAction } from "@/actions/logoutAction";

export const publicRoutes: RouteObject[] = [
  {
    path: "/",
    element: <LandingView />,
    errorElement: <ErrorView />,
  },
  {
    path: "login",
    element: <LoginView />,
    errorElement: <ErrorView />,
    action: loginAction,
  },
  {
    path: "logout",
    action: logoutAction,
  },
  {
    path: "url/:id",
    loader: urlShortnerLoader,
    errorElement: <ErrorView />
  },
  {
    path: "invite/:token",
    loader: inviteVerifyLoader,
    action: createUserAction,
    element: <CreateUserView />,
    errorElement: <ErrorView />
  }
];