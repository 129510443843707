interface Props {
  title: string;
  name: string;
}

export default function PasswordInput(props: Props) {
  return (
    <div className="text-main-blue">
      <p>{props.title}</p>
      <input
        className="p-1 bg-black border-2 border-main-blue rounded-md"
        type="password"
        name={props.name}
      />
    </div>
  );
}
