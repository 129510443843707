import { useNavigate, useRouteLoaderData, useSubmit } from "react-router-dom";
import { Sheet, SheetClose, SheetContent, SheetTitle, SheetTrigger } from "@/shadcd/components/ui/sheet";
import MainLogo from "../other/MainLogo";
import { FiLogOut, FiMenu} from "react-icons/fi";
import { UserDto } from "@/types/User";

const navigationButtons = [
  {
    title: "Utils",
    path: "/utils"
  },
  {
    title: "Settings",
    path: "/settings"
  },
  {
    title: "Games",
    path: "/games"
  },
  {
    title: "Chat",
    path: "/chat/rooms"
  },
]

export default function LeftNav() {
  const currentUser = useRouteLoaderData("protected") as UserDto
  const navigate = useNavigate();
  const submit = useSubmit();
   
  const handleNavigate = (to: string) => {
    navigate(to);
  }

  const handleLogout = (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("userId", currentUser.userId.toString());
    submit(formData, {
      method: "post",
      action: "logout",
    });
  };

  return (
    <Sheet>
      <SheetTrigger>
        <FiMenu className="text-main-blue" size={24}/>
      </SheetTrigger>
      <SheetContent side="left" className="bg-black border-main-blue">
        <div className="flex items-center justify-between">
          <SheetClose>
            <SheetTitle>
            <MainLogo animation="none"
                      tailwindSize="w-32"
                      onClick={() => handleNavigate("/home")}/>
            </SheetTitle>
          </SheetClose>
          <FiLogOut size={24} className="text-main-blue cursor-pointer hover:opacity-60" onClick={handleLogout}/>
        </div>
        <div className="relative flex flex-col">
          <div className="flex p-2 my-4 flex-col items-start h-full">

              {/* Render navigation buttons */}
              {navigationButtons.map((btn, index) => (
                <SheetClose key={index} asChild>
                    <button className="text-main-blue hover:opacity-60" onClick={() => handleNavigate(btn.path)}>{btn.title}</button>
                </SheetClose>
      
            ))}
          </div>
      </div>
      </SheetContent>
    </Sheet>
  );
}