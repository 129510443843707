import useApiLoader from "@/api/utils/useApiLoader";
import { useEffect, useState } from "react";

export default function HomeView() {
  const weather = useApiLoader<{temperature: number}>(false, true);

  return (  
    <div className="flex w-screen h-screen items-center justify-center flex-col h-full gap-3">
    	<div className="text-[64px] text-main-blue flex flex-col justify-center items-center">
        {weather && <p className="text-4xl">Salo {weather?.temperature}°C</p>}
        <Clock />
      </div>
		</div>
  );
}

// TODO: Move to utils?
const formatDigits = (x: number) => {
  if (x.toString().length === 2) {
    return x.toString();
  }
  return `0${x.toString()}`
}

// TODO: Move time utils ? :D
function getClockString() {
  const date = new Date();
  
  return [
    formatDigits(date.getHours()),
    formatDigits(date.getMinutes()),
    formatDigits(date.getSeconds()),
  ].join(":")
}

// TODO: Move
export function Clock() {
  const [clock, setClock] = useState(getClockString());

  useEffect(() => {
    const t = setInterval(() => setClock(getClockString()), 1000); // One second
    return () => clearInterval(t);
  }, [])

  return (
    <div className="text-[52px] md:text-[120px] text-main-blue">{clock}</div>
  )
}