import { RouteObject } from "react-router-dom";
import SettingsView from "../components/views/ProtectedView/settings/SettingsView";
import ChangePasswordView from "../components/views/ProtectedView/settings/change-password/ChangePasswordView";
import { changePasswordAction } from "../actions/changePasswordAction";
import ChangeRolesView from "../components/views/ProtectedView/settings/change-roles/ChangeRolesView";
import changeRolesLoader from "../loaders/changeRolesLoader";
import { changeRoleAction } from "../actions/changeRoleAction";
import InviteView from "@/components/views/ProtectedView/settings/invite/InviteView";
import inviteLoader from "@/loaders/inviteLoader";
import { inviteAction } from "@/actions/inviteAction";

export const settingsRoutes: RouteObject[] = [
  {
    path: "settings",
    element: <SettingsView />,
  },
  {
    path: "settings/change-password",
    element: <ChangePasswordView />,
    action: changePasswordAction,
  },
  {
    path: "settings/change-roles",
    element: <ChangeRolesView />,
    loader: changeRolesLoader,
    action: changeRoleAction
  },
  {
    path: "settings/invite",
    element: <InviteView />,
    loader: inviteLoader,
    action: inviteAction
  }
];

