import { ApiResponse } from "@/types/ApiResponse";
import { changePassword } from "../api/user";
import { UserDto } from "@/types/User";

export async function changePasswordAction({ request }: { request: Request }): Promise<ApiResponse<UserDto>> {
  const formData = await request.formData();
  const newPassword = formData.get("newPassword") as string;
  const newPasswordAgain = formData.get("newPasswordAgain") as string;

  const response = await changePassword(newPassword, newPasswordAgain);
  const data = await response.json();
  return data;
}