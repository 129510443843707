import ErrorView from "../components/views/ErrorView/ErrorView";
import { RouteObject } from "react-router-dom";
import HomeView from "../components/views/ProtectedView/home/HomeView";
import ProtectedView from "../components/views/ProtectedView/ProtectedView";
import { utilsRoutes } from "./utilsRoutes";
import { settingsRoutes } from "./settingsRoutes";
import authorizeLoader from "../loaders/authorizeLoader";
import { chatRoutes } from "./chatRoutes";
import { gameRoutes } from "./gameRoutes";
import homeLoader from "@/loaders/homeLoader";

export const protectedRoutes: RouteObject[] = [
  {
    loader: authorizeLoader,
    id: "protected",
    children: [
      {
        path: "/",
        element: <ProtectedView />,
        errorElement: <ErrorView />,
        children: [
          {
            path: "home",
            element: <HomeView />,
            loader: homeLoader
          },

          // Utils
          ...utilsRoutes,

          // Settings
          ...settingsRoutes,

          // Chat
          ...chatRoutes,

          // Game
          ...gameRoutes
        ],
      },
    ],
  },
];
