import { redirect } from "react-router";
import { authorization } from "../api/auth";
import { UserDto } from "@/types/User";
import { Role } from "@/types/Role";

export default async function authorizeLoader() {
  const response = await authorization();

  if (response.status !== 200) {
    localStorage.removeItem("token");
    return redirect("/login");
  }
  const data = await response.json() as UserDto;
  data.isAdmin = data.role === Role.ADMIN
  return data;
};
