import { useState } from "react";
import { Form, useActionData } from "react-router-dom";

export default function UrlShortenerView() {

  const [longUrl, setLongUrl] = useState("");
  const actionData = useActionData() as {shortUrl: string};

  return (
    <div className="absolute left-2 right-2 top-28 bottom-2 flex flex-col items-center gap-6 overflow-auto">
      <h1>Url Shortener</h1>
      <Form method="post" className="flex flex-col">
        <input type="text"
               name="longUrl"
               placeholder="Original url"
               className="bg-black text-main-blue grow px-2 border text-center"
               value={longUrl}
               onChange={e => setLongUrl(e.target.value)} />

        <button className="border border-main-blue rounded-lg mt-2">Proceed</button>
      </Form>

      {actionData?.shortUrl && <div className="text-center">
        <label className="">Short url</label>
          <p>{actionData.shortUrl}</p>
        </div>}
    </div>
  )
}