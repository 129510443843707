import { RouteObject } from "react-router-dom";
import ErrorView from "../components/views/ErrorView/ErrorView";
import RootView from "../components/views/RootView/rootView";
import { publicRoutes } from "./publicRoutes";
import { protectedRoutes } from "./protectedRoutes";

export const rootRoute: RouteObject[] = [
  {
    path: "/",
    id: "root",
    shouldRevalidate: () => false,
    element: <RootView />,
    errorElement: <ErrorView />,
    children: [
      // Public
      ...publicRoutes,
      // Protected
      ...protectedRoutes,
    ],
  },
];
