import useApiAction from "@/api/utils/useApiAction";
import SubmitButton from "@/components/buttons/SubmitButton";
import PasswordInput from "@/components/inputs/PasswordInput";
import TextInput from "@/components/inputs/TextInput";
import MainLogo from "@/components/other/MainLogo";
import { Form, useLoaderData } from "react-router-dom"

export default function CreateUserView() {
  const {token} = useLoaderData() as {token: string};
  useApiAction();

  return (
    <div className="w-screen h-screen flex justify-center items-center flex-col">
      <div className="flex justify-center mb-6">
        <MainLogo tailwindSize="w-44" />
      </div>
      <Form method="post" className="flex flex-col gap-3 items-center">
        <input type="hidden" name="token" value={token ?? ""} />
        <TextInput name="username" title="Username" />
        <PasswordInput name="password" title="Password" />
        <PasswordInput name="passwordAgain" title="Password again" />
        <SubmitButton title="Login" />
      </Form>
    </div>
  )
}
