import { API_BASE_URL } from "./config";

/**
 * Create a request to the backend
 * @param url 
 * @param method 
 * @param auth Default false 
 * @param body Optional
 * @returns 
 */
export const createRequest = async (url: string, method: string, auth=false, body?: any) => {
  const token = localStorage.getItem("token");

  const headers: any = {
    "Content-Type": "application/json",
  }

  if (auth && token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  
  return await fetch(`${API_BASE_URL}${url}`, {
    method,
    headers,
    body: body ? JSON.stringify(body) : null
  });
}