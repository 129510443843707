import { createRequest } from "./createRequest";

export async function getInviteDetails(): Promise<Response> {
  return await createRequest("/invite", "get", true);
}

export async function createNewInvite(): Promise<Response> {
  return await createRequest("/invite/new", "get", true);
}

export async function verifyToken(token: string): Promise<Response> {
  return await createRequest(`/invite/verify/${token}`, "post", false)
}
