import ChatRoomsView from "@/components/views/ProtectedView/chat/ChatRoomsView";
import ChatView from "@/components/views/ProtectedView/chat/ChatView";
import chatLoader from "@/loaders/chatLoader";
import chatRoomsLoader from "@/loaders/chatRoomsLoader";
import { RouteObject } from "react-router-dom";

export const chatRoutes: RouteObject[] = [
  {
    path: "chat/rooms",
    element: <ChatRoomsView />,
    loader : chatRoomsLoader
  },
  {
    path: "chat/:id",
    element: <ChatView />,
    loader: chatLoader
  },
];

