import Logo from "../../assets/cd5e-transparent-teal.png";

interface Props {
  tailwindSize?: string;
  animation?: string;
  onClick?: () => void;
}

export default function MainLogo(props: Props) {
  const animation = props.animation ?? "animate-bounce";
  const size = props.tailwindSize ?? "w-8/1";
  return (
    <div
      onClick={props.onClick}
      className={`flex self-center justify-center main-logo cursor-pointer ${size} ${animation}`}
    >
      <img className="logo" src={Logo} />
    </div>
  );
}
