import { Params, redirect } from "react-router-dom";
import { getLongUrl } from "../api/urlShortener";

// TODO: This gonna be replaced
export default async function urlShortnerLoader({params}: {params: Params}) {
  const id = params.id;
  
  if (id) {
    const response = await getLongUrl(id);
    
    if (response.ok) {
      const data = await response.json();
      return redirect(data.url);
    }
    return redirect("/")
  }
  return redirect("/")
}