import { Role } from "../types/Role";
import { createRequest } from "./createRequest";

export async function findAll(): Promise<Response> {
  return await createRequest("/user/all", "get", true);
}

export async function updateRole(userId: number, role: Role): Promise<Response> {
  return await createRequest("/user/update-role", "post", true, { userId, role });
}

export async function changePassword(newPassword: string, newPasswordAgain: string): Promise<Response> {
  return await createRequest("/user/change-password", "post", true, { newPassword, newPasswordAgain });
}

export async function createUser(token: string, username: string, password: string, passwordAgain: string): Promise<Response> {
  return await createRequest("/user/create", "post", true, { token, username, password, passwordAgain })
}